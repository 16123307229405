export default class Hamburger {
  constructor() {
    this.el = document.querySelector('.js-hamburger')

    this.el.addEventListener('click', () => {
      this.el.classList.toggle('is-active')
      document.body.classList.toggle('has-menu-open')
    })
  }
}
