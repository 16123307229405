import { ScrollTo } from '../modules/ScrollTo'

export default class Menu {
  constructor() {
    this.isSticky = false
    this.menuItems = document.querySelectorAll('.js-scroll-to')

    window.addEventListener('scroll', e => {
      this.handleVerticalScroll()
    })

    Array.prototype.forEach.call(this.menuItems, element => {
      element.addEventListener('click', e => {
        e.preventDefault()
        this.handleGoTo(e.target)
        return false
      })
    })
  }

  handleVerticalScroll() {
    if (window.scrollY === 0) {
      this.toggleSticky(0)
      this.isSticky = false
    }

    if (window.scrollY >= 100) {
      this.toggleSticky(1)
      this.isSticky = false
    }
  }

  toggleSticky(state) {
    if (state) {
      document.body.classList.add('is-menu-sticky')
    } else {
      document.body.classList.remove('is-menu-sticky')
    }
  }

  handleGoTo(el) {
    const target = el.getAttribute('href')
    const hamburger = document.querySelector('.js-hamburger')

    if (window.innerWidth < 1280) {
      hamburger.classList.remove('is-active')
      document.body.classList.remove('has-menu-open')
    }

    ScrollTo(target)
  }
}
