import Config from 'Config'

import AOS from 'aos'

import Router from '../modules/Router'
import Hamburger from '../components/Hamburger'
import Menu from '../components/Menu'
import Accordion from '../components/Accordion'
import Carousel from '../components/Carousel'

class Application {
  constructor() {
    this.config = Config

    new Router({
      config: this.config
    })

    new Hamburger()
    new Menu()
    new Accordion()
    new Carousel()

    AOS.init({
      delay: 250,
      duration: 400,
      easing: 'ease-in-out',
      once: true
    })
  }
}

export default Application
