import $ from 'jquery'

export const ScrollTo = target => {
  let gap = window.innerWidth < 1280 ? 70 : 120
  const distance = $(target).offset().top

  setTimeout(() => {
    $('html, body').animate(
      {
        scrollTop: distance - gap
      },
      600,
      'swing'
    )
  }, 400)
}
