import TweenMax from 'gsap'

export default class Accordion {
  constructor() {
    this.config = {
      elClass: '.js-accordion',
      singleItemClass: '.accordion__item',
      togglerClass: '.js-accordion-toggle',
      titleClass: '.accordion__title',
      contentClass: '.accordion__content',
      animationDuration: 0.175,
      openClass: 'is-open',
      showContentClass: 'show-content'
    }

    this.animateOpen = this.animateOpen.bind(this)
    this.animateClose = this.animateClose.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)

    this.el = document.querySelector(this.config.elClass)

    this.togglers = document.querySelectorAll(this.config.togglerClass)

    Array.prototype.forEach.call(this.togglers, element => {
      element.addEventListener('click', this.toggleAccordion)
    })
  }

  toggleAccordion(e) {
    const parent = e.target.parentNode
    const parentClassList = parent.classList

    if (parentClassList.contains(this.config.openClass)) {
      this.animateClose(parent)
    } else {
      parentClassList.add(this.config.openClass)
      this.animateOpen(parent)
    }
  }

  animateOpen(parent) {
    let height = 0

    height += parent.querySelector(this.config.titleClass).clientHeight
    height += parent.querySelector(this.config.contentClass).clientHeight

    TweenMax.to(parent, this.config.animationDuration, {
      height: `${height}px`,
      delay: 0.2,
      onComplete: () => {
        parent.classList.add(this.config.showContentClass)
      }
    })
  }

  animateClose(parent) {
    const baseHeight = window.innerWidth < 1280 ? 90 : 40
    parent.classList.remove(this.config.showContentClass)

    TweenMax.to(parent, this.config.animationDuration, {
      height: baseHeight,
      onComplete: () => {
        parent.classList.remove(this.config.openClass)
      }
    })
  }
}
